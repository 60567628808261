<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="p-1"
      >
        <div class="p-0 w-75 m-auto">
          <b-img
            src="@/assets/images/sqe/conseil_accueil.jpg"
            alt="Login V2"
            center
            fluid
          />
        </div>
        <b-card-group class="p-0 mt-1">
          <b-card>
            <b-card-text>Ce service vous permet <span class="font-weight-bolder">répondre aux exigences de surveillance des compétences des personnes impliquées dans les activités d'inspection</span> prescrites par la Norme ISO 17020 §6.1.8.</b-card-text>
            <b-card-text>
              Cette surveillance peut être réalisée par le biais de questionnaires d'évaluation et de validation des compétences pour chaque personne impliquée dans les activités d'inspection; à savoir : Les Responsables Techniques
            </b-card-text>
            <b-card-text>
              Les Inspecteurs, Les Aides-opérateurs, Les Secrétaires, Opérateurs de saisie des rapports, Les Directeurs, Commerciaux ou Chefs d'agence.
              Nota : la surveillance des inspecteurs, pour être totale, doit être complétée par une observation sur site (sur chantier) par domaine de compétence.
            </b-card-text>
            <b-card-text>
              Pour chaque type de personne impliquées dans les activités d'inspection, le serveur propose <span class="font-weight-bolder">un questionnaire de 20 à 30 questions à choix multiples </span> par domaine de compétence : contrôles de compactage, inspections visuelles ou tests d'étanchéité, parmi un pool d'environ 200 questions par domaine.
            </b-card-text>
            <b-card-text>
              A la fin du questionnaire, une note sur 20 sera calculée et une suggestion de qualification sera proposée. Vous pourrez exporter votre note sous format PDF. Vous avez la possibilité de créditer sur un compte client autant de questionnaires que vous le souhaitez. Pour commencer créer votre compte, si vous n'en avez pas, et créditez au moins 1 questionnaire.
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto mt-5"
        >
          <Logo class="mx-auto d-block" />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-4"
          >
            {{ $t('WELCOME') }} !
          </b-card-title>
          <b-alert
            variant="danger"
            :show="errorShow"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                {{ $t("ERROR_LOGIN") }}
              </p>
            </div>
          </b-alert>
          <b-alert
            variant="danger"
            :show="loginError"
          >
            <div class="alert-body font-small-2">
              <p class="mr-50">
                Email ou mot de passe incorrect
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="checkLogin()"
            >
              <!-- email -->
              <b-form-group
                label="Email :"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                    @focus="loginError = false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">{{ $t('PASSWORD') }} :</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      @focus="loginError = false"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-link @click="goToRenewLoginDemand()">
                Mot de passe oublié ?
              </b-link>
              <!-- submit buttons -->
              <b-button
                v-if="loading"
                variant="primary"
                type="button"
                block
                disabled
              >
                <b-spinner small /> {{ $t('LOADING_BUTTON') }} ...
              </b-button>
              <b-button
                v-else
                variant="primary"
                type="submit"
                block
                class="mt-1"
              >
                {{ $t('LOGIN') }}
              </b-button>
              <b-button
                variant="secondary"
                block
                @click="goToAddAccount()"
              >
                Créer un compte
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BAlert, BRow, BCol, BFormGroup, BFormInput,
  BInputGroupAppend, BInputGroup, BCardTitle,
  BImg, BForm, BButton, BSpinner, BCard, BCardText,
  BCardGroup, BLink,
} from 'bootstrap-vue'

import Logo from '@/views/components/Logo.vue'

import fr from 'vee-validate/dist/locale/fr.json'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

localize('fr', fr)

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    Logo,
    BCard,
    BCardText,
    BCardGroup,
    BLink,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      required,
      email,
      loading: false,
      errorShow: false,
      loginError: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    localStorage.removeItem('internationalisation')
  },
  methods: {
    goToAddAccount() {
      this.$router.push({ name: 'account-new' })
    },
    goToRenewLoginDemand() {
      this.$router.push({ name: 'renew-password-demand' })
    },
    checkLogin() {
      const that = this
      this.loading = true
      this.errorShow = false

      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/login', { email: this.username, password: this.password }).then(
            response => {
              localStorage.setItem('token', response.token)
              localStorage.setItem('userData', JSON.stringify(response.user))
              this.$store.dispatch('flashinfo/getAllFlashinfos').then(
                data => {
                  if (response.user.idAdmin) {
                    this.$router.push({ name: 'admin-utilisateurs' })
                  } else {
                    this.$router.push({ name: 'mes-evaluations' })
                  }
                },
              )
            },
            () => {
              this.loginError = true
            },
          )
          that.loading = false
        } else {
          that.loading = false
          this.errorShow = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
